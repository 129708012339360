<script>
import Page from "./page.vue";
export default {
  name: "Maintenance",
  extends: Page,
  data() {
    return {
      title: "503: SERVICE UNAVAILABLE",
      message: "Servizio in manutenzione. Riprovare più tardi.",
      action: this.go,
    };
  },
  methods: {
    go() {
      this.$router.replace({
        name: "Login",
      });
    },
  },
};
</script>
